.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  max-width: 450px;
  margin: 0 auto;
}
.home-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-text {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text01 {
  box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-image {
  width: 320px;
  object-fit: cover;
}
.home-turquoise-cirble {
  top: 439px;
  left: auto;
  right: 430px;
  width: 170px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-left {
  top: 653px;
  left: 0px;
  right: auto;
  width: 420px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-right {
  top: 441px;
  left: auto;
  right: 0px;
  width: 612px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-clients {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: center;
}
.home-divider {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-container02 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-image01 {
  width: 100px;
  object-fit: cover;
}
.home-image02 {
  width: 100px;
  object-fit: cover;
}
.home-image03 {
  width: 100px;
  object-fit: cover;
}
.home-image04 {
  width: 100px;
  object-fit: cover;
}
.home-image05 {
  width: 100px;
  object-fit: cover;
}
.home-image06 {
  width: 100px;
  object-fit: cover;
}
.home-image07 {
  width: 100px;
  object-fit: cover;
}
.home-image08 {
  width: 100px;
  object-fit: cover;
}
.home-image09 {
  width: 100px;
  object-fit: cover;
}
.home-image10 {
  width: 100px;
  object-fit: cover;
}
.home-image11 {
  width: 100px;
  object-fit: cover;
}
.home-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.home-divider1 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-cta {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.home-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container06 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text08 {
  max-width: inherit;
}
.home-container07 {
  width: 520px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image12 {
  top: -100px;
  left: auto;
  right: var(--dl-space-space-doubleunit);
  width: 232px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-image13 {
  top: auto;
  left: var(--dl-space-space-doubleunit);
  right: auto;
  width: 232px;
  bottom: -100px;
  position: absolute;
  object-fit: cover;
}
.home-benefits {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container08 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.home-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.home-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.home-testimonial {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: 232323;
}
.home-container14 {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container15 {
  width: 100%;
  /* display: grid; */
  /* grid-gap: 32px; */
  /* grid-template-columns: 1fr 1fr 1fr; */
}
.home-achievements {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container16 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container17 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container18 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container19 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.home-link {
  display: contents;
}
.home-container21 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-link1 {
  display: contents;
}
.home-container22 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-link2 {
  display: contents;
}
.home-container23 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-link3 {
  display: contents;
}
.home-container24 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-link4 {
  display: contents;
}
.home-container25 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-link5 {
  display: contents;
}
.home-container26 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-link6 {
  display: contents;
}
.home-container27 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-container28 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-image14 {
  object-fit: cover;
}
.home-container29 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text44 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
.home-container30 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link7 {
  display: contents;
}
.home-container31 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-container31:hover {
  background-color: var(--dl-color-linimitra-pink);
}
.home-icon {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
}
.home-link8 {
  display: contents;
}
.home-container32 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-container32:hover {
  background-color: var(--dl-color-linimitra-purple);
}
.home-icon2 {
  width: 24px;
  height: 24px;
}


.home-container01 {
  width: 100%;
}
.home-text {
  width: auto;
  font-size: 38px;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 0px;
  text-align: center;
}
.home-text01 {
  font-size: 40px;
  box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px;
  font-style: normal;
  margin-top: var(--dl-space-space-doubleunit);
  text-align: center;
  font-weight: 800;
  border-color: var(--dl-color-gray-300);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-unit);
  background-color: var(--dl-color-linimitra-secondary);
}
.home-container02 {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.home-text02 {
  font-style: normal;
  text-align: center;
  font-weight: 800;
}
.home-text03 {
  text-align: center;
}
.home-text05 {
  font-size: 40px;
  box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 800;
  margin-left: 0px;
  border-color: var(--dl-color-gray-300);
  border-width: 1px;
  margin-right: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 0px;
  padding-right: var(--dl-space-space-unit);
  background-color: var(--dl-color-linimitra-secondary);
}
.home-container03 {
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.home-image01 {
  width: auto;
  height: 60px;
  margin: var(--dl-space-space-halfunit);
}
.home-image02 {
  margin: var(--dl-space-space-halfunit);
}
.home-image03 {
  width: auto;
  height: 56px;
  margin: var(--dl-space-space-halfunit);
  align-self: center;
}
.home-image04 {
  width: auto;
  height: 67px;
  margin: var(--dl-space-space-halfunit);
}
.home-image05 {
  width: auto;
  height: 53px;
  margin: var(--dl-space-space-halfunit);
}
.home-image06 {
  width: 78px;
  height: auto;
  margin: var(--dl-space-space-halfunit);
}
.home-image07 {
  width: 78px;
  height: auto;
  margin: var(--dl-space-space-halfunit);
}
.home-image08 {
  width: 78px;
  height: auto;
  margin: var(--dl-space-space-halfunit);
}
.home-image09 {
  width: 78px;
  height: auto;
  margin: var(--dl-space-space-halfunit);
}
.home-image10 {
  width: 78px;
  height: auto;
  margin: var(--dl-space-space-halfunit);
}
.home-image11 {
  width: 78px;
  height: auto;
  margin: var(--dl-space-space-halfunit);
}
.home-container04 {
  width: auto;
  height: auto;
  padding: var(--dl-space-space-halfunit);
  margin-top: 0px;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: var(--dl-color-purple-800);
}
.home-text06 {
  width: auto;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.home-text07 {
  width: 100%;
  font-size: 36px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  font-style: normal;
  margin-top: 0px;
  text-align: center;
  font-weight: 800;
  border-color: var(--dl-color-gray-300);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-unit);
  background-color: var(--dl-color-linimitra-secondary);
}
.home-text08 {
  text-transform: capitalize;
}
.home-text13 {
  color: var(--dl-color-linimitra-primary);
  font-weight: 700;
  text-decoration: underline;
}
.home-text14 {
  color: var(--dl-color-linimitra-primary);
  text-decoration: underline;
}
.home-text23 {
  font-style: normal;
  text-align: center;
  font-weight: 800;
}
.home-text24 {
  text-align: center;
}
.home-text26 {
  font-size: 40px;
  box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 800;
  border-color: var(--dl-color-gray-300);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-unit);
  background-color: var(--dl-color-linimitra-secondary);
}
.home-container08 {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  flex-direction: column;
}
.home-container09 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container10 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container11 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  justify-content: center;
}
.home-container12 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  justify-content: center;
}
.home-container13 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  justify-content: center;
}
.home-testimonial {
  padding: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-turquoise-100);
}
.home-container14 {
  padding: 0px;
}
.home-text27 {
  color: var(--dl-color-gray-black);
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  margin-bottom: var(--dl-space-space-doubleunit);
  text-align: center;
}
.home-achievements {
  height: auto;
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-container16 {
  align-items: center;
}
.home-text28 {
  text-align: center;
  font-size: 38px;
  font-weight: 800;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text29 {
  text-align: center;
  font-size: 1rem;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-container17 {
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.home-container18 {
  flex-direction: column;
}
.home-container19 {
  flex-direction: column;
}
.home-text30 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
  align-self: center;
  font-size: 1rem;
  font-weight: 600;
}
.home-container20 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  height: auto;
  flex-wrap: wrap;
  justify-content: center;
}
.home-container21 {
  gap: 6px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-linimitra-primary);
}
.home-text33 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
}
.home-container22 {
  gap: 6px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-linimitra-secondary);
}
.home-text34 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
}
.home-container23 {
  gap: 6px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.home-text35 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-weight: 600;
}
.home-container24 {
  gap: 6px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-pink-default);
}
.home-text36 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
}
.home-container25 {
  gap: 6px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-linimitra-purple);
}
.home-text37 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
}
.home-container26 {
  gap: 6px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-purple-1000);
}
.home-text38 {
  color: var(--dl-color-gray-white);
}
.home-text39 {
  font-style: normal;
  font-weight: 600;
}
.home-container27 {
  gap: 6px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-800);
}
.home-text41 {
  color: var(--dl-color-gray-white);
}
.home-image14 {
  width: 198px;
  height: 100%;
}
.home-container29 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text44 {
  color: var(--dl-color-gray-300);
  width: 100%;
  margin: 0px;
  margin-top: 0px;
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text48 {
  font-size: 14px;
}
.home-text49 {
  font-size: 14px;
}
.home-text50 {
  font-size: 14px;
}
.home-text51 {
  font-size: 14px;
}
.home-text52 {
  font-size: 14px;
}
.home-container30 {
  gap: 10px;
  width: auto;
}
.home-container31 {
  gap: 6px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
}
.home-text54 {
  font-size: 14px;
}
.home-container32 {
  gap: 6px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
}
.home-icon2 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
}
.home-text55 {
  font-size: 14px;
}
