.feature-card-container {
  width: 320px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.feature-card-image {
  width: var(--dl-size-size-doubleunit);
  height: var(--dl-size-size-doubleunit);
  object-fit: cover;
}
.feature-card-text {
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  text-align: center;
}









@media(max-width: 767px) {
  .feature-card-container {
    align-items: center;
    justify-content: center;
  }
  .feature-card-text {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .feature-card-image {
    width: var(--dl-size-size-tripleunit);
    height: var(--dl-size-size-tripleunit);
  }
  .feature-card-text {
    margin-top: var(--dl-space-space-halfunit);
    text-align: center;
    margin-bottom: 0px;
  }
  .feature-card-root-class-name {
    flex: 1;
    width: auto;
    margin: 0px;
    align-self: center;
  }
  .feature-card-root-class-name1 {
    flex: 1;
    width: auto;
    margin: 0px;
  }
  .feature-card-root-class-name2 {
    flex: 1;
    width: auto;
    margin: 0px;
    align-self: center;
  }
  .feature-card-root-class-name3 {
    flex: 1;
    width: auto;
    height: auto;
    margin: 0px;
  }
  .feature-card-root-class-name4 {
    flex: 1;
    width: auto;
    margin: 0px;
    align-self: center;
  }
  .feature-card-root-class-name5 {
    flex: 1;
    width: auto;
    margin: 0px;
  }
  .feature-card-root-class-name6 {
    flex: 1;
    width: auto;
    margin: 0px;
    align-self: center;
  }
  .feature-card-root-class-name7 {
    flex: 1;
    width: auto;
    margin: 0px;
  }
  .feature-card-root-class-name8 {
    flex: 1;
    width: auto;
    margin: 0px;
    align-self: center;
  }
}
